<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Confirmar Cotización')}}
            </template>
            <template v-slot:content>
                <div class="p-field p-col-12">
                    {{$t('Información Básica')}}
					<DataTable :value="[formData.datos]" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
							:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
						<Column field="Datos" :header="$t('Número ID')">
							<template #body="slotProps">
								{{slotProps.data.code}}
							</template>
						</Column>
						<Column field="Datos" :header="$t('Nombre Completo')">
							<template #body="slotProps">
								{{slotProps.data.nombre}}
							</template>
						</Column>
					</DataTable>
                </div>
                
                <div class="p-field p-col-12">
                    {{$t('Información del Vehículo')}}
					<DataTable :value="[formData.vehiculo]" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
							:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
						<Column field="Vehículo" :header="$t('Tipo de Vehículo')">
							<template #body="slotProps">
								{{slotProps.data.tipo.nombre}}
							</template>
						</Column>
						<Column field="Vehículo" :header="$t('Marca')">
							<template #body="slotProps">
								{{slotProps.data.marca.nombre}}
							</template>
						</Column>
						<Column field="Vehículo" :header="$t('Módelo')">
							<template #body="slotProps">
								{{slotProps.data.modelo.nombre}}
							</template>
						</Column>
						<Column field="Vehículo" :header="$t('Fecha de compra')">
							<template #body="slotProps">
								{{convfech(slotProps.data.compra)}}
							</template>
						</Column>
					</DataTable>
                </div>
               
                <div class="p-field p-col-12">
                    {{$t('Información de la Licencia')}}
					<DataTable :value="formData.licencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
							:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
						<Column field="Licencia" :header="$t('Licencia')">
							<template #body="slotProps">
								{{slotProps.data.categoria}}
							</template>
						</Column>
						<Column field="Licencia" :header="$t('Nombre Completo')">
							<template #body="slotProps">
								{{slotProps.data.nombre}}
							</template>
						</Column>
						<Column field="Licencia" :header="$t('Fecha de emisión')">
							<template #body="slotProps">
								{{convfech(slotProps.data.emision)}}
							</template>
						</Column>
						<Column field="Licencia" :header="$t('Fecha de caducidad')">
							<template #body="slotProps">
								{{convfech(slotProps.data.caducidad)}}
							</template>
						</Column>
					</DataTable>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Cotizar')" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			size: '60vw',
			i18n: null,
        }
    },
	created() {
		this.i18n = useI18nPlugin();
	},
    methods: {
        prevPage() {
            this.$emit('prev-page', {pageIndex: 4});
        },
        fdate(dato){
            const [day, month, year] = dato.split('/');
            return new Date(+year, +month - 1, +day);
        },
        complete() {
            this.$store.commit('Loading');
			var licencias = this.formData.licencias;
			licencias.forEach(element => {
				element.emision = this.fdate(element.emision);
				element.caducidad = this.fdate(element.caducidad);
			});
			var fecha = null;
			if(this.formData.datos.tipocliente.code!=2){
				fecha = this.fdate(this.formData.datos.fecha);
			}
            const Consulta = new API('Cotizacions');
            Consulta.Procesar('Vehiculo',{		
				caso: 	'INS',
				nuevo: this.formData.nuevo,
				datos: this.formData.datos,
                fecha: fecha,
                cardemi: this.fdate(this.formData.vehiculo.cardemi),
				vehiculo: this.formData.vehiculo,
				licencias: licencias,
				adicional: this.formData.adicional,
				leng: this.i18n.locale(),
			}).then(response => {
				//this.$store.state.error =response;
				if (response.result) {
                    this.$toast.add({
                        severity:'success',
                        summary: 'Éxito',
                        detail: 'Información Procesada', 
                        life: 3000
                    });
                    this.$router.push({ path: '/segquotation'});
				} else {
					this.$toast.add({
                        severity:'warn',
                        summary: 'Advertencia',
                        detail: 'No fue procesada la solicitud', 
						life: 3000
                    });
				}
                this.$store.commit('Loading');
			});
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},

    }
}
</script>